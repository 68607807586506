var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-frame"},[_c('div',{staticClass:"container-text-title-top container-item"},[_c('span',{staticClass:"msg-card-title title-black",domProps:{"innerHTML":_vm._s(
          _vm.messageItem.messageResponse.content.location.Location.BranchName
        )}},[_vm._v(_vm._s(_vm.messageItem.messageResponse.content.location.Location.BranchName))])]),_c('hr'),_c('div',{staticClass:"container-item"},[_c('div',{staticClass:"lower-row display-line"},[_c('div',{staticClass:"icon-section"},[_c('div',{staticClass:"img-icon-display",style:({
              backgroundImage:
                'url(' +
                _vm.messageItem.messageResponse.content.location.Location
                  .IconBranch +
                ')',
            })})]),_c('div',{staticClass:"info-section"},[_c('span',{staticClass:"info",domProps:{"innerHTML":_vm._s(
              _vm.messageItem.messageResponse.content.location.Location.Address
            )}},[_vm._v(_vm._s(_vm.messageItem.messageResponse.content.location.Location.Address))])])]),_c('div',{staticClass:"lower-row display-line"},[_c('div',{staticClass:"icon-section"},[_c('div',{staticClass:"img-icon-display",style:({
              backgroundImage:
                'url(' +
                _vm.messageItem.messageResponse.content.location.Location
                  .IconOpenHour +
                ')',
            })})]),_c('div',{staticClass:"info-section"},[_c('span',{staticClass:"info",domProps:{"innerHTML":_vm._s(
              _vm.messageItem.messageResponse.content.location.Location.OpenHour
            )}},[_vm._v(_vm._s(_vm.messageItem.messageResponse.content.location.Location.OpenHour))])])]),_c('div',{staticClass:"lower-row display-line"},[_c('div',{staticClass:"icon-section"},[_c('div',{staticClass:"img-icon-display",style:({
              backgroundImage:
                'url(' +
                _vm.messageItem.messageResponse.content.location.Location
                  .IconCutOffTime +
                ')',
            })})]),_c('div',{staticClass:"info-section"},[_c('span',{staticClass:"info",domProps:{"innerHTML":_vm._s(
              _vm.messageItem.messageResponse.content.location.Location.CutOffTime
            )}},[_vm._v(_vm._s(_vm.messageItem.messageResponse.content.location.Location.CutOffTime))])])]),_c('div',{staticClass:"lower-row display-line"},[_c('div',{staticClass:"icon-section"},[_c('div',{staticClass:"img-icon-display",style:({
              backgroundImage:
                'url(' +
                _vm.messageItem.messageResponse.content.location.Location
                  .IconTel +
                ')',
            })})]),_c('div',{staticClass:"info-section"},[_c('a',{staticClass:"info text-tel",attrs:{"href":`tel:${_vm.messageItem.messageResponse.content.location.Location.Tel}`}},[_vm._v(_vm._s(_vm.messageItem.messageResponse.content.location.Location.Tel))])])]),_c('div',{staticClass:"lower-row display-line"},[_c('div',{staticClass:"icon-section"},[_c('div',{staticClass:"img-icon-display",style:({
              backgroundImage:
                'url(' +
                _vm.messageItem.messageResponse.content.location.Location
                  .ServiceIcon +
                ')',
            })})]),_c('div',{staticClass:"info-section"},[_c('span',{staticClass:"info",domProps:{"innerHTML":_vm._s(_vm.strService)}},[_vm._v(_vm._s(_vm.strService))])])])]),_c('hr'),_c('div',{staticClass:"column container-item"},[_c('button',{staticClass:"list-btn",style:({ backgroundColor: _vm.primaryColor }),attrs:{"type":"button"},on:{"click":() => {
            _vm.handleOpenTab(
              _vm.messageItem.messageResponse.content.location.Location.BranchUrl
            );
          }}},[_vm._v(" "+_vm._s(_vm.messageItem.messageResponse.content.location.ButtonLabel)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }