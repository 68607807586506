<template>
  <div v-if="messageItem.messageResponse.content.responseType != 17">
    <!-- type: 1 Text -->
    <div
      v-if="
        (messageItem.messageResponse.content.responseType == 1 ||
          (messageItem.messageResponse.content.responseType == 2 &&
            messageItem.senderId == userId)) &&
          messageItem.messageResponse.content.content.text
      "
      class="container-all-item"
    >
      <div class="" v-if="messageItem.senderId != userId && agentImg">
        <img class="img-agent" :src="`${agentImg}`" />
      </div>

      <div
        :class="[
          messageItem.senderId == userId ? 'my-msg' : 'res-msg all-width',
        ]"
      >
        <div class="msg-item">
          <div
            class="agent-name non-left"
            v-if="messageItem.senderId != userId && agentName"
          >
            <span>{{ agentName }}</span>
          </div>
          <div>
            <p
              v-html="
                replaceURLs(
                  messageItem.messageResponse.content.content.text
                ).replace(/(?:\r\n|\r|\n)/g, '<br />')
              "
              class="item item-text border-res-msg"
            >
              {{
              replaceURLs(messageItem.messageResponse.content.content.text).replace(/(?:\r\n|\r|\n)/g,
              '<br />') }}
            </p>
          </div>
        </div>

        <!-- user img profile -->
        <div v-if="messageItem.senderId == userId && userImg">
          <img class="img-user" :src="`${userImg}`" />
        </div>
      </div>
    </div>

    <!-- type: 6 user share Location -->
    <div
      v-if="
        messageItem.messageResponse.content.responseType == 6 &&
          messageItem.senderId == userId
      "
      class="container-all-item"
    >
      <div class="my-msg">
        <div
          class="msg-item item-location"
          @click="
            handleOpenTab(
              'https://www.google.com/maps/search/?api=1&query=' +
                messageItem.messageResponse.content.location.latitude +
                ',' +
                messageItem.messageResponse.content.location.longitude
            )
          "
        >
          <div>
            <div class="msg-map">
              <img
                class="img-map"
                v-bind:src="urlImgMap"
                height=""
                width="100%"
              />
            </div>
            <p
              v-bind:style="[{ backgroundColor: secondaryColor }]"
              class="item mes-address"
            >
              {{ messageItem.messageResponse.content.location.address }}
            </p>
          </div>
        </div>

        <!-- user img profile -->
        <div v-if="messageItem.senderId == userId && userImg">
          <img class="img-user" :src="`${userImg}`" />
        </div>
      </div>
    </div>

    <!-- type: 4 Video from user, 5 File from user -->
    <div
      v-if="
        messageItem.senderId == userId &&
          (messageItem.messageResponse.content.responseType == 5 ||
            messageItem.messageResponse.content.responseType == 4)
      "
      class="container-all-item"
    >
      <!-- agent img profile -->
      <div v-if="messageItem.senderId != userId && agentImg">
        <img class="img-agent" :src="`${agentImg}`" />
      </div>

      <div class="my-msg">
        <div class="msg-item">
          <div
            class="agent-name non-left"
            v-if="messageItem.senderId != userId && agentName"
          >
            <span>{{ agentName }}</span>
          </div>
          <div
            v-for="(item, i) in messageItem.messageResponse.content.imageList
              .imageUrl"
            :key="i"
          >
            <p
              class="item item-file border-res-msg"
              @click="handleOpenTab(item.link)"
            >
              <font-awesome-icon
                icon="file-download"
                class="icon-file-download"
              />
              {{ item.name }}
            </p>
          </div>
        </div>

        <!-- user img profile -->
        <div v-if="messageItem.senderId == userId && userImg">
          <img class="img-user" :src="`${userImg}`" />
        </div>
      </div>
    </div>

    <!-- type: 8 res file -->
    <div
      v-if="
        messageItem.senderId != userId &&
          messageItem.messageResponse.content.responseType == 8
      "
      class="container-all-item"
    >
      <!-- agent img profile -->
      <div v-if="messageItem.senderId != userId && agentImg">
        <img class="img-agent" :src="`${agentImg}`" />
      </div>

      <div class="res-msg all-width">
        <div class="msg-item">
          <div
            class="agent-name non-left"
            v-if="messageItem.senderId != userId && agentName"
          >
            <span>{{ agentName }}</span>
          </div>
          <div
            v-for="(item, i) in messageItem.messageResponse.content.imageList
              .imageUrl"
            :key="i"
          >
            <p
              class="item item-file border-res-msg"
              @click="handleOpenTab(item.url)"
            >
              <font-awesome-icon
                icon="file-download"
                class="icon-file-download"
              />
              {{ item.name }}
            </p>
          </div>
        </div>

        <!-- user img profile -->
        <div v-if="messageItem.senderId == userId && userImg">
          <img class="img-user" :src="`${userImg}`" />
        </div>
      </div>
    </div>

    <!-- type: 3 Image from user, 4 response Image -->
    <div
      :class="[messageItem.senderId == userId ? 'my-img' : 'res-img all-width']"
      v-if="
        (messageItem.messageResponse.content.responseType == 3 &&
          messageItem.senderId == userId) ||
          (messageItem.senderId != userId &&
            messageItem.messageResponse.content.responseType == 4)
      "
    >
      <div class="">
        <!-- agent img profile -->
        <div v-if="messageItem.senderId != userId && agentImg">
          <img class="img-agent" :src="`${agentImg}`" />
        </div>
      </div>
      <div class="all-width">
        <div
          class="agent-name"
          v-if="messageItem.senderId != userId && agentName"
        >
          <span>{{ agentName }}</span>
        </div>
        <div
          v-for="(item, i) in messageItem.messageResponse.content.imageList
            .imageUrl"
          :key="i"
          class="img_upload"
        >
          <div
            class="img_upload-display"
            v-bind:style="{
              backgroundImage: `url(&quot;${item}&quot;)`,
            }"
            @click="handleOpenTab(item)"
          ></div>
        </div>
      </div>
      <div v-if="messageItem.senderId == userId && userImg" class="">
        <div>
          <img class="img-user" :src="`${userImg}`" />
        </div>
      </div>
    </div>

    <!-- type: 2 Text Button, 3 Text Image, 5 Flex, 6 Flex Carousel, 7 Image Carousel, 9 Location -->
    <div
      v-if="
        !messageItem.senderId &&
          (messageItem.messageResponse.content.responseType == 2 ||
            messageItem.messageResponse.content.responseType == 3 ||
            messageItem.messageResponse.content.responseType == 5 ||
            messageItem.messageResponse.content.responseType == 6 ||
            messageItem.messageResponse.content.responseType == 7 ||
            messageItem.messageResponse.content.responseType == 9 ||
            messageItem.messageResponse.content.responseType == 10 ||
            messageItem.messageResponse.content.responseType == 11 ||
            messageItem.messageResponse.content.responseType == 18 ||
            messageItem.messageResponse.content.responseType == 19)
      "
      class="container-all-item"
    >
      <div v-if="agentImg">
        <img class="img-agent" :src="`${agentImg}`" />
      </div>

      <div class="all-width">
        <div class="agent-name" v-if="agentName">
          <span>{{ agentName }}</span>
        </div>
        <div
          :class="[
            messageItem.messageResponse.content.responseType != 7
              ? 'bg-white'
              : 'non-border',
            messageItem.messageResponse.content.responseType == 9
              ? 'extent-width-location'
              : 'extent-width',
          ]"
          class="msg_cotainer_send extent-width border-res-msg"
        >
          <ChatBoxFlex
            @setShowMap="setShowMap"
            @send-message="sendMessage"
            :messageItem="messageItem"
          />
        </div>
        <ChatBoxButtonQuikReply
          @setShowMap="setShowMap"
          @send-message="sendMessage"
          :messageItem="messageItem"
        />
      </div>
    </div>
    <div
      v-if="
        !(
          messageItem.messageResponse.content.responseType == 1 &&
          !messageItem.messageResponse.content.content.text
        ) &&
          !(
            messageItem.messageResponse.content.responseType == 2 &&
            messageItem.messageResponse.content.content.buttons &&
            messageItem.messageResponse.content.content.buttons.length > 0 &&
            messageItem.messageResponse.content.content.buttons.some(
              (contenBtn) => contenBtn.Type == 2
            )
          )
      "
      :class="[
        messageItem.senderId == userId ? 'date-user' : 'date-agent',
        userImg ? 'date-user-img-user' : '',
      ]"
    >
      <ChatDateTime
        :date="messageItem.createdTime"
        :senderId="messageItem.senderId"
      />
    </div>
  </div>
</template>

<script>
import config from "./config.js";
import ChatDateTime from "./ChatDateTime.vue";
import ChatBoxFlex from "./chatBoxFlex/ChatBoxFlex.vue";
import ChatBoxButtonQuikReply from "./chatBoxFlex/ChatBoxButtonQuikReply.vue";
export default {
  name: "ChatMessageItem",
  components: {
    ChatDateTime,
    ChatBoxFlex,
    ChatBoxButtonQuikReply,
  },
  props: {
    messageItem: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (
      this.messageItem.messageResponse.content.responseType == 6 &&
      this.messageItem.senderId == this.userId
    ) {
      this.urlImgMap =
        "https://maps.googleapis.com/maps/api/staticmap?center=" +
        this.messageItem.messageResponse.content.location.latitude +
        "," +
        this.messageItem.messageResponse.content.location.longitude +
        "&zoom=" +
        this.messageItem.messageResponse.content.location.zoom +
        "&size=600x300&maptype=roadmap&markers=color:red%7C%7C" +
        this.messageItem.messageResponse.content.location.latitude +
        "," +
        this.messageItem.messageResponse.content.location.longitude +
        "&key=" +
        config.apiKeyGoogleMap;        
    }
  },
  data() {
    return {
      userId: this.$cookies.get("d-user-info").userId,
      primaryColor: config.color,
      secondaryColor: config.secondaryColor,
      agentImg: require("../../assets/images/N_Karry.jpeg"),
      userImg: config.userIcon,
      agentName: config.agentName,
      urlImgMap: "",
    };
  },
  methods: {
    sendMessage(dataSend, type, attachment, textShow) {
      this.$emit("send-message", dataSend, type, attachment, textShow);
    },
    handleOpenTab(url) {
      window.open(url, "_blank");
    },
    setShowMap(show) {
      this.$emit("setShowMap", show);
    },
    replaceURLs(message) {
      if (!message) return;
      var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
      return message.replace(urlRegex, function(url) {
        var hyperlink = url;
        if (!hyperlink.match("^https?://")) {
          hyperlink = "http://" + hyperlink;
        }
        return (
          '<a href="' +
          hyperlink +
          '" target="_blank" rel="noopener noreferrer" style="color: #007bff;" >' +
          url +
          "</a>"
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-msg {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 0px;
}
.res-msg {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.msg-item {
  width: auto;
  max-width: 80%;
  margin: 0px 0px 0px 5px;
}
.item {
  overflow-wrap: break-word;
  word-break: break-word;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
}
.content-start {
  display: flex;
  flex-flow: row;
}
.my-img {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}
.res-img {
  display: flex;
  flex-flow: row;
}
.my-img .img_upload {
  height: auto;
  width: auto;
  max-width: 100%;
  min-width: 50px;
  padding-left: 30%;
}
.res-img .img_upload {
  height: auto;
  width: auto;
  max-width: 70%;
  min-width: 50px;
  padding-left: 5px;
}
.my-img .img_upload-display {
  width: 100%;
  padding-bottom: 100%;
  padding-bottom: 56.25%;
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
}
.res-img .img_upload-display {
  width: 100%;
  padding-bottom: 100%;
  padding-bottom: 56.25%;
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
}
.img_upload-display:hover {
  cursor: pointer;
}
.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 15px;
  color: #000;
  position: relative;
  min-width: 25%;
}
.extent-width {
  width: 80% !important;
  margin-left: 5px;
}
.extent-width-location {
  width: 95% !important;
  margin-left: 5px;
}
.img-agent {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  position: relative;
  bottom: 2px;
  align-items: flex-start;
}
.img-user {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  position: relative;
  bottom: 5px;
  margin-left: 5px;
}
.border-res-msg {
  border: 1px solid #e0e0e0;
  color: #616161;
}
.msg-map {
  border: solid #e0e0e0;
  border-width: 2px 2px 0px 2px;
  color: #616161;
  border-radius: 15px 15px 0 0;
}
.vue-map-container {
  width: 100%;
  height: 200px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.vue-map-container .vue-map {
  width: 100%;
  height: 200px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.agent-name {
  color: #999999;
  font-size: 20px;
  margin-left: 5px;
  margin-bottom: 5px;
}
.item-file {
  margin-top: 3px;
  margin-bottom: 1px;
  padding: 10px 12px;
  border-radius: 15px;
  display: block;
  color: #595a5c;
  font-size: 20px;
}

.item-file:hover {
  cursor: pointer;
  text-decoration: underline;
}
.icon-file-download {
  height: 1rem !important;
  width: auto !important;
}
.non-left {
  margin-left: 0px;
}
.text-white {
  color: white;
}
.item-text {
  margin-top: auto;
  margin-bottom: auto;
  padding: 10px 12px;
  border-radius: 15px;
  display: block;
  color: #595a5c;
  font-size: 20px;
}
.mes-address {
  margin-top: auto;
  margin-bottom: auto;
  padding: 10px 12px;
  display: block;
  border: solid #e0e0e0;
  border-width: 0px 2px 2px 2px;
  color: #595a5c;
  font-size: 20px;
  border-radius: 0 0 15px 15px;
}
.all-width {
  width: 90%;
}
.date-user {
  display: flex;
  justify-content: flex-end;
}
.date-user-img-user {
  display: flex;
  justify-content: flex-end;
  margin-right: 3rem;
}
.date-agent {
  display: flex;
  justify-content: flex-start;
  margin-left: 50px;
}
.bg-white {
  background-color: white;
}
.non-border {
  border: 0px;
}
.container-all-item {
  margin-top: 0rem;
  display: flex;
  flex-flow: row;
}
.item-location:hover {
  cursor: pointer;
}
.img-map {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  vertical-align: middle;
}
a {
  font-size: 20px;
  cursor: pointer;
  color: inherit;
  text-decoration: underline;
}
a:hover {
  font-size: 20px;
  cursor: pointer;
  color: inherit;
  text-decoration: underline;
}
</style>
