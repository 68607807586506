var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-frame"},[_c('div',[_c('div',{staticClass:"display-line container-text-title-top container-item"},[_c('div',{staticClass:"img-icon-title-display",style:({
          backgroundImage:
            'url(' +
            _vm.messageItem.messageResponse.content.location.IconTitle +
            ')',
        })}),_c('span',{staticClass:"msg-card-title title-black",domProps:{"innerHTML":_vm._s(_vm.messageItem.messageResponse.content.location.Title)}},[_vm._v(_vm._s(_vm.messageItem.messageResponse.content.location.Title))])]),_c('hr'),_c('div',{staticClass:"container-item container-location",style:({ borderColor: _vm.primaryColor })},[_vm._l((_vm.messageItem.messageResponse.content.location
          .Branch),function(location,j){return _c('div',{key:j,staticClass:"item-location",on:{"click":() => {
            _vm.sendMessagePostback(location.BranchUrl, 2);
          }}},[_c('div',{staticClass:"row-item"},[_c('div',{staticClass:"display-line item-location-name"},[_c('div',{staticClass:"icon-section"},[_c('div',{staticClass:"img-icon-branch-display",style:({
                  backgroundImage: 'url(' + location.IconBranch + ')',
                })})]),_c('div',{staticClass:"info-section"},[_c('span',{staticClass:"info",domProps:{"innerHTML":_vm._s(location.BranchName)}},[_vm._v(" "+_vm._s(location.BranchName))])])]),_c('span',{staticClass:"item-distance",domProps:{"innerHTML":_vm._s(location.Distance)}},[_vm._v(_vm._s(location.Distance)+" ")]),_c('font-awesome-icon',{staticClass:"icon-go",style:({ color: _vm.primaryColor }),attrs:{"icon":"chevron-right"}})],1),_c('div',{staticClass:"lower-row display-line"},[_c('div',{staticClass:"icon-clock-section"},[_c('div',{staticClass:"img-icon-time-display",style:({
                backgroundImage: 'url(' + location.IconOpenHour + ')',
              })})]),_c('div',{staticClass:"info-clock-section"},[_c('span',{staticClass:"item-time info",domProps:{"innerHTML":_vm._s(location.OpenHour)}},[_vm._v(" "+_vm._s(location.OpenHour))])])]),_c('div',{staticClass:"row-item lower-row time-round",style:({ color: _vm.primaryColor })},[_c('span',{domProps:{"innerHTML":_vm._s(location.CutOffTime)}},[_vm._v(_vm._s(location.CutOffTime))])])])}),_c('div',{staticClass:"container-btn"},[_c('button',{staticClass:"list-btn",style:({ backgroundColor: _vm.primaryColor }),attrs:{"type":"button"},on:{"click":() => {
              _vm.handleClickButton(
                _vm.messageItem.messageResponse.content.location.Button.Action,
                _vm.messageItem.messageResponse.content.location.Button.Label,
                _vm.messageItem.messageResponse.content.location.Button.Data
              );
            }}},[_vm._v(" "+_vm._s(_vm.messageItem.messageResponse.content.location.Button.Label)+" ")])])],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }