var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.messageItem.messageResponse.content.responseType == 2)?_c('div',{staticClass:"container-frame"},[_c('div',{staticClass:"container-item"},[_c('span',{staticClass:"msg_wrap",domProps:{"innerHTML":_vm._s(
          _vm.messageItem.messageResponse.content.content.text.replace(
            /(?:\r\n|\r|\n)/g,
            '<br />'
          )
        )}},[_vm._v(" {{ messageItem.messageResponse.content.content.text.replace(/(?:\\r\\n|\\r|\\n)/g, '"),_c('br'),_vm._v("') }} ")])]),(
        _vm.messageItem.messageResponse.content.content.buttons &&
          _vm.messageItem.messageResponse.content.content.buttons.length > 0 &&
          _vm.messageItem.messageResponse.content.content.buttons.some(
            (contenBtn) => contenBtn.Type != 2
          )
      )?_c('hr'):_vm._e(),_vm._l((_vm.messageItem.messageResponse.content.content.buttons),function(btn,j){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(btn.Type != 2),expression:"btn.Type != 2"}],key:j,staticClass:"column container-item"},[(btn.Action != 8)?_c('button',{staticClass:"list-btn",style:({ backgroundColor: _vm.themeColor }),attrs:{"type":"button"},on:{"click":() => {
            _vm.handleClickButton(btn.Action, btn.Label, btn.Data);
          }}},[_vm._v(" "+_vm._s(btn.Label)+" ")]):_c('a',{staticClass:"list-btn btn-tel",style:({ backgroundColor: _vm.themeColor }),attrs:{"href":`tel:${btn.Label}`}},[_vm._v(" "+_vm._s(btn.Label)+" ")])])})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }