<template>
  <div class="panel-chat">
    <div id="chatBar" class="modal-chat-bar">
      <span v-if="!showChatBox" class="text-title">{{ formTitle }}</span>
      <span v-else class="text-title">{{ title }}</span>

      <div class="container-hide-btn" @click="unDisplayModalChat">
        <font-awesome-icon icon="window-minimize" class="hide-btn" />
      </div>
      <div class="container-close-btn" @click="hideModalChat">
        <font-awesome-icon icon="times" class="close-btn" />
      </div>
    </div>
    <div class="container-layout">
      <ChatForm @setShowChatBox="setShowChatBox" v-if="!showChatBox" />
      <ChatBox v-if="showChatBox" :showChatBox="showChatBox" />
    </div>
  </div>
</template>

<script>
import config from "./config.js";
import ChatForm from "./ChatForm.vue";
import ChatBox from "./ChatBox.vue";

export default {
  name: "ChatLayout",
  components: {
    ChatForm,
    ChatBox,
  },
  mounted() {
    if (
      this.$cookies.isKey("next-i18next") &&
      this.$cookies.get("next-i18next") == "en"
    ) {
      this.formTitle = config.preChatForm.formTitleEn;
    } else {
      this.formTitle = config.preChatForm.formTitle;
    }
    document.getElementById("chatBar").style.backgroundColor = config.color;
  },
  data() {
    return {
      title: config.title,
      showChatBox: false,
      formTitle: config.preChatForm.formTitle,
    };
  },
  methods: {
    hideModalChat() {
      this.$emit("setShowModal", false);
      this.$emit("setDisplayModal", false);
      this.handleEndChat();
    },
    unDisplayModalChat() {
      this.$emit("setDisplayModal", false);
    },
    setShowChatBox(show) {
      this.showChatBox = show;
    },
    async handleEventEndChat(event) {
      event.preventDefault();
      try {
        // API call here
        await this.handleEndChat();
        return ""; // Prompt with default browser message
      } catch (err) {
        return undefined; // Skip prompt
      }
    },
    async handleEndChat() {
      if (
        this.$cookies.isKey("d-user-info") &&
        this.$cookies.isKey("d-chat-ct") &&
        parseInt(this.$cookies.get("d-chat-ct")) < 2
      ) {
        // let bodyEndChat = {
        //   brandId: config.brandId,
        //   platformId: config.platformId,
        //   channelReferenceId: config.channelReferenceId,
        //   userGUID: this.$cookies.get("d-user-info").userId,
        // };
        // await this.$axios({
        //   method: "post",
        //   url: config.chatBaseUrl + "/user/endchat",
        //   data: bodyEndChat,
        //   headers: {
        //     "API-KEY": config.apiKey,
        //     "Content-Type": "application/json",
        //   },
        // });

        // ----------------
        // let bodyEndChat = {
        //   brandId: config.brandId,
        //   platformId: config.platformId,
        //   channelReferenceId: config.channelReferenceId,
        //   userGUID: this.$cookies.get("d-user-info").userId,
        // };
        // let headers = {
        //   type: "text/plain; charset=UTF-8",
        // };
        // let blob = new Blob([JSON.stringify(bodyEndChat)], headers);
        // navigator.sendBeacon(config.chatBaseUrl + "/user/endchat", blob);

        // ---------------
        let formData = new FormData();
        formData.append("brandId", config.brandId);
        formData.append("platformId", config.platformId);
        formData.append("channelReferenceId", config.channelReferenceId);
        formData.append("userGUID", this.$cookies.get("d-user-info").userId);
        navigator.sendBeacon(
          config.chatBaseUrl + "/user/EndChatForm",
          formData
        );

        // ------------------
        // let formData = new FormData();
        // formData.append("brandId", 1);
        // formData.append("platformId", 2);
        // formData.append("channelReferenceId", "WEB_KERRY");
        // formData.append("userGUID", "4b0f92fd-f8c7-4caf-8f01-f45d6c315be7");
        // navigator.sendBeacon("https://kerry-uat-message-api.d3-dots.com/api/user/endchat", formData);
      }
    },
  },
  created() {
    window.addEventListener("beforeunload", this.handleEndChat);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.handleEndChat);
  },
};
</script>

<style lang="scss" scoped>
.panel-chat {
  width: 100%;
  height: 100%;
  box-shadow: 0 0.5rem 1rem rgba(black, 0.15);
  border-radius: 10px;
}
.modal-chat-bar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0.5rem;
}
.text-title {
  color: white;
  font-size: 20px;
  font-family: "DBHeavent";
}
.close-btn {
  color: white;
  font-size: 20px;
  font-family: "DBHeavent";
}
.close-btn:hover {
  cursor: pointer;
}
.container-layout {
  height: auto;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0;
}
.container-close-btn {
  top: auto;
  position: absolute;
  right: 15px;
  cursor: pointer;
  display: block;
}
.container-close-btn svg {
  height: 0.82rem;
  width: auto;
  margin-top: 6px;
}

.container-hide-btn {
  top: auto;
  position: absolute;
  right: 40px;
  cursor: pointer;
  display: block;
  color: white;
}
.container-hide-btn svg {
  height: 0.72rem;
  width: auto;
  margin-top: 4px;
}
@media (max-width: 767.98px) {
  .panel-chat {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
  }
  .modal-chat-bar {
    border-radius: 0;
  }
  .container-layout {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
</style>
